import { useEffect, useState } from 'react'
import { useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import Lottie from 'lottie-react'
import RequestError from './RequestError'
import Denied from './Denied'
import Pending from './Pending'
import JoinSuccess from '../../components/JoinSuccess'

export default ({ transactionId, cardData, setCardData }) => {
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded')
  const [paymentStatus, setPaymentStatus] = useState(null)
  const { loading, response } = useAuthorizedFetch(`${uri}/payments/${transactionId}`, {
    method: 'POST',
    body: JSON.stringify(cardData)
  })

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setPaymentStatus(data)
      })
    } else if (!loading) {
      setPaymentStatus('Request Error')
    }
  }, [loading])

  const handleSubmit = () => {
    if (embedded) {
      window.open('viatik:///error')
    } else {
      window.location.href = window.location.origin
    }
  }

  return (
    <>
      {loading || !paymentStatus ? (
        <div className='flex flex-col w-full items-center justify-center h-screen'>
          <Lottie
            animationData={require('../../assets/animations/loading_payment.json')}
            autoplay
            loop
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            style={{ height: 150, width: 150 }}
          />
          <p className='text-lg text-primary'>Verificando tu pago...</p>
        </div>
      ) : paymentStatus?.status == 'approved' ? (
        <JoinSuccess />
      ) : paymentStatus?.status == 'denied' || paymentStatus?.status == 'failed' ? (
        <Denied
          status={paymentStatus?.status}
          title={paymentStatus?.title}
          message={paymentStatus?.message}
          transactionId={transactionId}
          setCardData={setCardData}
        />
      ) : paymentStatus?.status == 'pending' ? (
        <Pending
          title={paymentStatus?.title}
          message={paymentStatus?.message}
          onSubmit={handleSubmit}
        />
      ) : (
        <RequestError status={response.status} />
      )}
    </>
  )
}
