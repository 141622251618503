import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons'
import { TextField, ThemeProvider } from '@mui/material'
import { containsContactInfo, muiInputTheme } from '../../utils'
import { WarningAmberRounded } from '@mui/icons-material'
import { useEffect, useState } from 'react'

export default ({ sendMessage, disabled, channel, user }) => {
  const hideHeader = new URLSearchParams(window.location.search).get('hide-header')
  const otherUsersName = channel?.users?.filter(u => u.user_id !== user)?.[0]?.name
  const [message, setMessage] = useState('')

  const handleKeyDown = e => {
    if (e.key === 'Enter' && e.shiftKey === false && message.trim().length > 0 && !isMobile()) {
      sendMessage(message)
      if (!containsContactInfo(message)) setMessage('')
      e.preventDefault()
    }
  }

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
  }

  return (
    <>
      <div className={`${channel?.type !== 'broadcast' && 'hidden'} pb-[3.5rem]`} />
      <div
        className={`flex flex-row items-center justify-center px-4 pt-1 pb-2 gap-2 bg-light_gray ${
          (!channel || channel?.type !== 'direct-message') && 'hidden'
        } ${disabled && 'pb-[5.5rem]'}`}
      >
        <WarningAmberRounded sx={{ color: disabled ? '#ff6700' : '#ef4444' }} />
        {disabled ? (
          <p className='text-sm text-warning'>
            Podés seguir hablando con {otherUsersName} a través del chat grupal del viaje.
          </p>
        ) : (
          <p className='text-xs text-error'>
            Evita pagar fuera de la plataforma. Perderás todas las garantías, te volves vulnerable
            ante fraudes y puede resultar en la suspensión permanente de tu cuenta.
          </p>
        )}
      </div>
      <div
        className={`flex flex-row items-center w-full gap-3 mt-3 px-4 ${
          hideHeader && channel?.lobby_id
            ? 'pb-20'
            : channel?.lobby_id
            ? 'pb-[9rem]'
            : hideHeader
            ? 'pb-4'
            : 'pb-20'
        } ${disabled && 'hidden'}
          `}
      >
        <ThemeProvider theme={muiInputTheme}>
          <TextField
            style={{ flexGrow: 1 }}
            multiline
            maxRows={4}
            InputProps={{ style: { padding: 10 } }}
            value={message}
            onChange={e => setMessage(e.target.value)}
            disabled={disabled}
            onKeyDown={handleKeyDown}
          />
        </ThemeProvider>
        <div
          onClick={() => {
            if (message.trim().length == 0) return
            sendMessage(message)
            if (!containsContactInfo(message)) setMessage('')
          }}
          className={`flex items-center justify-center min-w-10 min-h-10 max-w-10 max-h-10 w-10 h-10 rounded-full ${
            message.trim().length > 0 ? 'bg-light_coral focus:bg-primary' : 'bg-gray'
          } cursor-pointer`}
        >
          <FontAwesomeIcon icon={faPaperPlane} color='white' />
        </div>
      </div>
    </>
  )
}
