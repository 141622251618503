import { Mixpanel } from '../../Mixpanel'
import Button from '../../commons/Button'

const Denied = ({ status, title, message, transactionId, setCardData }) => {
  const handleSubmit = () => {
    Mixpanel.track(`Volver a intentar - ${status}`, {
      'Transaction Id': transactionId
    })
    setCardData(null)
  }

  return (
    <div className='flex flex-col justify-between h-full py-10'>
      <div className='flex flex-col gap-9 items-center justify-center px-8'>
        <div className='flex flex-col gap-3 justify-center items-center'>
          <div className='flex bg-[#f96062] rounded-full w-24 h-24 items-center justify-center'>
            <img
              src={require('../../assets/img/card_error.png')}
              alt='rechazado'
              className='w-14'
            />
          </div>
          <p className='text-base'>Algo salió mal...</p>
        </div>
        <div className='flex flex-col gap-6 items-center justify-center'>
          <p className='text-lg font-bold text-primary text-center'>{title}</p>
          <p className='text-base text-center'>{message}</p>
        </div>
      </div>
      <div className='flex justify-center items-center'>
        <Button
          name='Volver a intentar'
          style={{ margin: 'auto', fontSize: 16 }}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

export default Denied
