const SearchError = ({ text, linkcolor, noLink }) => {
  return (
    <div className='m-auto flex flex-col justify-center items-center px-6 gap-4'>
      <img src={require('../../assets/img/error.png')} className='w-72' />
      <p className='text-xl'>¡Ups!</p>
      <p>{text || 'Ha ocurrido un error, por favor revisá tu conexión e intentá nuevamente.'}</p>
      {!noLink && (
        <>
          <p>Si el error persiste contactate con nosotros.</p>
          <div
            className={`flex-col flex gap-2  ${
              linkcolor ? `text-[${linkcolor}]` : 'text-light_coral'
            }`}
          >
            <a
              href='https://api.whatsapp.com/send/?phone=+59898415699&text=Hola,%20tuve%20un%20problema%20usando%20la%20app'
              target='_blank'
            >
              WhatsApp
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default SearchError
