import Button from '../../commons/Button'

const Pending = ({ title, message, onSubmit }) => {
  return (
    <div className='flex flex-col justify-between h-full py-10'>
      <div className='flex flex-col gap-9 items-center justify-center px-8'>
        <div className='flex bg-[#ffa15a] rounded-full w-24 h-24 items-center justify-center'>
          <img src={require('../../assets/img/pending.png')} alt='pendiente' className='w-14' />
        </div>
        <div className='flex flex-col gap-6 items-center justify-center'>
          <p className='text-lg font-bold text-primary text-center'>{title}</p>
          <p className='text-base text-center'>{message}</p>
        </div>
      </div>
      <div className='flex justify-center items-center'>
        <Button name='Continuar' style={{ margin: 'auto', fontSize: 16 }} onClick={onSubmit} />
      </div>
    </div>
  )
}

export default Pending
