import apiConfig from '../../config/Api'
import { DIAS, MESES } from '../../config/App'
import { useAuthorizedFetch } from '../../hooks'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Divider, IconButton } from '@mui/material'
import { ArrowBackIosNew, ChevronRight, Lock, WindowOutlined } from '@mui/icons-material'
import Trip from '../Trip'
import Button from '../../commons/Button'
import SearchError from '../../commons/SearchError'
import PaySafeScreen from '../PaySafeScreen'
import { Mixpanel } from '../../Mixpanel'
import LoadingIndicator from '../../commons/LoadingIndicator'
import { getCurrency } from '../../utils'
import Join from '../../commons/LobbyInfo/Join'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: 0,
    borderWidth: 0
  },
  overlay: {
    zIndex: 50,
    backgroundColor: 'rgba(0,0,0,0)'
  }
}

const VerifyLobby = ({ goBack, joinData, id, lobby }) => {
  const [data, setData] = useState(null)
  const origin_name = lobby.Journey.start_name
    ? lobby.Journey.start_name.formatted_address
    : lobby.Journey.origin_name.formatted_address
  const destination_name = lobby.Journey.end_name
    ? lobby.Journey.end_name.formatted_address
    : lobby.Journey.destination_name.formatted_address
  const departureDateTime = new Date(
    new Date(lobby.Journey.departure_time).getTime() + 3 * 60 * 60 * 1000
  )
  const departureTime = lobby.Journey.departure_time.substr(
    lobby.Journey.departure_time.indexOf('T') + 1,
    5
  )
  const formattedDate =
    DIAS[departureDateTime.getDay()] +
    departureDateTime.getDate() +
    ' de ' +
    MESES[departureDateTime.getMonth()] +
    ', ' +
    departureTime +
    'hs'
  const [paySafeScreen, setPaySafeScreen] = useState(false)
  const [joinLobby, setJoinLobby] = useState(false)

  const { loading, response } = useAuthorizedFetch(`${apiConfig.uri}/lobbies/${id}/request-join`, {
    method: 'POST',
    body: JSON.stringify({
      ...joinData,
      back_urls: {
        success: `${window.location.origin}/?navigation=JoinSuccess&lobby_id=${id}`
      }
    })
  })

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setData(data)
      })
    }
  }, [loading])

  const handlePaySubmit = preference_id => {
    Mixpanel.track('Ir a pagar', {
      'Lobby ID': id,
      'Preferencia ID': preference_id
    })
    window.location.href = window.location.origin + '/payment/' + data.transaction_id
  }

  const handleNoPaySubmit = () => {
    Mixpanel.track('Confirmar Unirse', {
      Lobby: id
    })
    setJoinLobby(true)
  }

  const handleOtherPaymentOption = () => {
    Mixpanel.track('Pagar por transferencia', {
      Lobby: id
    })
  }

  return (
    <Modal isOpen={true} style={customStyles}>
      {loading ? (
        <div className='flex flex-col items-center justify-center h-full'>
          <LoadingIndicator />
        </div>
      ) : joinLobby ? (
        <Join joinData={joinData} id={id} handleClose={() => setJoinLobby(false)} />
      ) : paySafeScreen ? (
        <PaySafeScreen setVisible={setPaySafeScreen} />
      ) : (
        <>
          <div>
            <IconButton onClick={goBack} style={{ paddingTop: 10 }} sx={{ color: '#e65944' }}>
              <ArrowBackIosNew />
            </IconButton>
          </div>
          {response.ok ? (
            <div className='h-full overflow-auto flex-col flex justify-between'>
              <div className='w-full flex flex-col mt-4 text-2xl gap-4 sm:items-center'>
                <p className='text-2xl pl-6 mb-3 pr-5'>Verifique los datos del viaje</p>
                <div className='pl-5'>
                  <Trip
                    dateStyle={{ marginBottom: 10 }}
                    start={origin_name}
                    end={destination_name}
                    originName={lobby.Journey.origin_name.formatted_address}
                    destinationName={lobby.Journey.destination_name.formatted_address}
                    departureDate={formattedDate}
                    departureTime={''}
                    arrivalTime={''}
                    noTime={true}
                  />
                </div>
                <Divider style={{ borderBottomWidth: 7, borderColor: '#f3f4f6' }} />
                <div className='flex flex-row gap-5 text-xl mt-2 mb-3 pl-7 font-thin'>
                  <p className='text-light_coral'>Cantidad asientos: </p>
                  <p>{data?.seats}</p>
                </div>
                <div className='flex flex-row gap-5 text-xl mb-2 pl-7 font-thin'>
                  <p className='text-light_coral'>Precio total: </p>
                  <p>
                    {getCurrency(lobby.currency)} {data?.price}
                  </p>
                </div>
                <Divider style={{ borderBottomWidth: 7, borderColor: '#f3f4f6' }} />
              </div>
              <div className='flex flex-col pr-6 pl-6 items-center mb-5 mt-5'>
                <div
                  className='flex flex-row gap-1 text-base text-[#9ca3af] mb-3 cursor-pointer'
                  onClick={() => setPaySafeScreen(true)}
                >
                  <Lock />
                  <p>Pago seguro</p>
                  <ChevronRight />
                </div>
                {!loading && data?.hasToPay ? (
                  <>
                    <Button
                      id={'button'}
                      name={'Ir a pagar'}
                      onClick={() => handlePaySubmit(data?.preference_id)}
                    />
                    <a
                      target='_blank'
                      href={`https://api.whatsapp.com/send/?phone=+59898415699&text=Hola, quiero pagar el viaje @${id.slice(
                        -5
                      )} con otra forma de pago`}
                      onClick={handleOtherPaymentOption}
                      className='text-light_coral mt-4 text-base cursor-pointer'
                    >
                      Consultar por otras formas de pago
                    </a>
                  </>
                ) : (
                  <Button id={'button'} name={'Confirmar'} onClick={handleNoPaySubmit} />
                )}
              </div>
            </div>
          ) : response.status === 409 ? (
            <div className='flex flex-col items-center justify-center h-full pl-3 pr-3 text-center'>
              <p className='text-xl mb-5'>¡Ups!</p>
              <p className='text-xl mb-5'>Ya te encuentras en este viaje</p>
              <p className='text-lg'>
                Puedes ver los detalles del viaje en la sección "Mis viajes" y acceder al chat en la
                sección "Mensajes"
              </p>
            </div>
          ) : (
            <SearchError />
          )}
        </>
      )}
    </Modal>
  )
}

export default VerifyLobby
