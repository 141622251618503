export const customization = {
  visual: {
    hideFormTitle: true,
    style: {
      theme: 'default',
      customVariables: {
        baseColor: '#d65944',
        baseColorFirstVariant: '#f25c45',
        baseColorSecondVariant: '#f25c45',
        successColor: '#05B456',
        textSecondaryColor: '#9ca3af',
        textPrimaryColor: '#212427',
        formPadding: '28px',
        inputVerticalPadding: '14px',
        outlinePrimaryColor: '#9ca3af',
        inputBorderWidth: '0.5px',
        fontSizeLarge: '14px',
        fontSizeMedium: '14px'
      }
    },
    texts: {
      cardholderName: {
        label: 'Nombre del titular',
        placeholder: 'Ej.: María López'
      }
    }
  },
  paymentMethods: {
    minInstallments: 1,
    maxInstallments: 1
  }
}
