import Button from '@mui/material/Button'

const NewButton = ({ type, id, name, onClick, style, className, disabled }) => {
  return (
    <Button
      id={id}
      name={id}
      type={type}
      variant='contained'
      style={{
        fontSize: '18px',
        backgroundColor: disabled ? '#9ca3af' : '#d65944',
        color: 'white',
        borderRadius: '30px',
        textTransform: 'none',
        paddingLeft: '20%',
        paddingRight: '20%',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 'semi-bold',
        boxShadow: 'none',
        ...style
      }}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </Button>
  )
}

export default NewButton
