import { CardPayment } from '@mercadopago/sdk-react'
import BackButton from '../../commons/BackButton'
import { customization } from './mercadopagoCustomization'
import PoweredBy from './PoweredBy'

export default ({ handleBack, data, onSubmit }) => {
  return (
    <>
      <div className='flex w-full mb-3 md:hidden'>
        <BackButton handleBack={handleBack} />
      </div>
      <div
        className='flex w-full py-2 mb-1 px-5 hidden md:block cursor-pointer'
        onClick={handleBack}
      >
        <p className='text-base text-light_coral'>Volver</p>
      </div>
      <p className='text-lg mb-8'>Completá los datos de tu tarjeta</p>
      <div className='px-6 md:px-4 w-full mb-4'>
        <CardPayment
          initialization={{
            amount: data.price,
            preferenceId: data.preference_id,
            payer: {
              email: data.email,
              entityType: 'individual'
            }
          }}
          customization={customization}
          onSubmit={onSubmit}
        />
      </div>
      <PoweredBy />
    </>
  )
}
