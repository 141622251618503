import Button from '../../commons/Button'
import SearchError from '../../commons/SearchError'
import JoinSuccess from '../../components/JoinSuccess'

const RequestError = ({ status }) => {
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded')

  const errorText =
    status === 419
      ? 'Tu sesión ha expirado'
      : 'Ha ocurrido un error, por favor revisá tu conexión e intentá nuevamente.'

  const handleClick = () => {
    if (status === 404 || status === 403 || status === 419) {
      if (embedded) {
        window.open('viatik:///error')
      } else {
        window.location.href = '/'
      }
    } else if (status === 409) {
      if (embedded) {
        window.open('viatik:///success')
      } else {
        window.location.href = '/messages'
      }
    } else {
      window.location.reload()
    }
  }

  if (status === 409) {
    return <JoinSuccess />
  }

  return (
    <div className='flex flex-col text-center justify-center items-center py-10'>
      <SearchError text={errorText} noLink={status === 419} />
      <div className='w-full bottom-10 absolute z-30 text-center'>
        <Button name='Aceptar' onClick={handleClick} style={{ margin: 'auto', fontSize: 18 }} />
      </div>
    </div>
  )
}

export default RequestError
