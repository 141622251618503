import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { initMercadoPago } from '@mercadopago/sdk-react'
import { useEffect, useState } from 'react'
import { useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import LoadingIndicator from '../../commons/LoadingIndicator'
import ProcessPayment from './ProcessPayment'
import { Mixpanel } from '../../Mixpanel'
import RequestError from './RequestError'
import SavedCards from './SavedCards'
import SavedCardForm from './SavedCardForm'
import PoweredBy from './PoweredBy'
import CardForm from './CardForm'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

initMercadoPago(process.env.REACT_APP_MERCADOPAGO_KEY, { locale: 'es-UY' })

export default ({ transactionId }) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/payments/${transactionId}`)
  const { loading: loadingCards, response: responseCards } = useAuthorizedFetch(
    `${uri}/payments/saved-cards`
  )
  const [data, setData] = useState(null)
  const [savedCards, setSavedCards] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [showCardForm, setShowCardForm] = useState(false)
  const [cardData, setCardData] = useState(null)

  const handleShowForm = () => {
    setShowForm(true)
  }

  const handleBack = () => {
    setShowForm(false)
    setShowCardForm(false)
  }

  const handleSavedCard = card => {
    setShowCardForm(card)
  }

  useEffect(() => {
    if (responseCards.ok) {
      responseCards.json().then(data => {
        setSavedCards(data.cards)
      })
    } else if (!loadingCards) {
      Mixpanel.track('Error al obtener tarjetas guardadas', {
        'Transaction Id': transactionId,
        'Error': responseCards
      })
    }
  }, [loadingCards])

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setData(data)
      })
    } else if (!loading) {
      Mixpanel.track('Error al obtener pago', {
        'Transaction Id': transactionId,
        'Error': response
      })
    }
  }, [loading])

  const onSubmit = cardData => {
    Mixpanel.track('Boton pagar', {
      'Transaction Id': transactionId
    })
    setCardData({
      token: cardData.token,
      payer: {
        ...cardData.payer,
        email: cardData.payer?.email || data.email
      }
    })
  }

  if (!loading && !response.ok) {
    return <RequestError status={response.status} />
  }

  if (loading || !data) {
    return (
      <div className='w-full flex justify-center items-center bg-light_gray h-screen'>
        <LoadingIndicator />
      </div>
    )
  }

  if (cardData) {
    return (
      <ProcessPayment transactionId={transactionId} cardData={cardData} setCardData={setCardData} />
    )
  }

  return (
    <div className='flex flex-col w-full items-center pb-6 overflow-auto flex-grow h-full bg-light_gray md:px-10'>
      <div className='flex flex-row w-full items-center justify-between bg-white pt-3 pb-2 mb-8 text-base px-6 rounded-b-lg md:rounded-lg md:py-4'>
        <PriceAccordion data={data} />
      </div>
      {showCardForm ? (
        <SavedCardForm card={showCardForm} onBack={handleBack} onSubmit={onSubmit} />
      ) : showForm ? (
        <CardForm handleBack={handleBack} data={data} onSubmit={onSubmit} />
      ) : (
        <div className='px-6 text-center w-full h-full flex flex-col justify-between'>
          <div>
            <p className='text-lg mb-8'>¿Cómo querés pagar?</p>
            <SavedCards cards={savedCards} loading={loadingCards} onPress={handleSavedCard} />
            <AddPaymentMethod handleShowForm={handleShowForm} />
          </div>
          <PoweredBy />
        </div>
      )}
    </div>
  )
}

const PriceAccordion = ({ data }) => {
  return (
    <Accordion
      sx={{
        width: '100%',
        boxShadow: 'none',
        paddingRight: 0,
        paddingLeft: 0
      }}
    >
      <AccordionSummary>
        <div className='flex w-full justify-between'>
          <p>Total</p>
          <p>$ {data.price}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='flex w-full justify-between items-center font-thin mb-1 text-sm'>
          <p>
            {data.seats} x {data.seats > 2 ? 'asientos' : 'asiento'}
          </p>
          <p>$ {data.price_without_fee}</p>
        </div>
        <div className='flex w-full justify-between items-center font-thin mb-1 text-sm'>
          <p>Gastos de gestión</p>
          <p>$ {data.fee}</p>
        </div>
        {data.discount > 0 && (
          <div className='flex w-full justify-between items-center font-thin mb-1 text-sm'>
            <p>Cupón</p>
            <p>- $ {data.discount}</p>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

const AddPaymentMethod = ({ handleShowForm }) => {
  return (
    <>
      <p className='mb-3 text-start text-lg'>Agregar método de pago</p>
      <div
        className='rounded-lg flex flex-row items-center p-5 w-full cursor-pointer bg-white shadow-sm'
        onClick={handleShowForm}
      >
        <div className='flex items-center justify-center rounded-full p-2 border-2 border-light_gray'>
          <FontAwesomeIcon icon={faCreditCard} className='w-6 h-6 text-primary' />
        </div>
        <p className='ml-4 font-thin text-sm text-start sm:text-base tall:text-base'>
          Tarjeta de crédito o débito
        </p>
        <FontAwesomeIcon icon={faChevronRight} className='w-3 h-3 text-gray ml-auto' />
      </div>
    </>
  )
}
