import { TextField } from '@mui/material'
import SnackBar from '../../commons/Snackbar'
import NextButton from './NextButton'
import { useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import { useEffect, useState } from 'react'
import LoadingIndicator from '../../commons/LoadingIndicator'

export default ({ referral, setReferral, handleSubmit }) => {
  const [validate, setValidate] = useState(false)

  return (
    <div className='flex flex-col px-6 text-2xl mt-6 overflow-auto sm:items-center'>
      <div className='sm:w-1/2'>
        <p className='text-start mb-6'>¿Sos un conductor referido?</p>
        <TextField
          id='referral'
          onChange={evt => setReferral(evt.target.value)}
          placeholder='Código de referido'
          sx={{ width: '100%' }}
          value={referral}
          variant='filled'
          InputProps={{
            disableUnderline: true,
            style: { borderRadius: 10, paddingTop: 10, paddingBottom: 10 }
          }}
          inputProps={{
            maxLength: 30,
            style: {
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              paddingTop: 8,
              textTransform: referral.trim().length > 0 ? 'uppercase' : 'none'
            }
          }}
        />
        <p className='text-start text-gray text-base mt-6 ml-1'>
          Si un usuario te envió su código de referido, utilizalo para que ambos puedan recibir
          beneficios.
        </p>
        {validate ? (
          <Validate referral={referral} setValidate={setValidate} handleSubmit={handleSubmit} />
        ) : (
          <NextButton
            onSubmit={() => {
              setValidate(true)
            }}
            disabled={referral.trim().length < 5}
          >
            <button
              onClick={() => {
                setReferral('')
                handleSubmit()
              }}
              className='text-lg text-light_coral'
            >
              Omitir
            </button>
          </NextButton>
        )}
      </div>
    </div>
  )
}

const Validate = ({ referral, setValidate, handleSubmit }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const { loading, response } = useAuthorizedFetch(`${uri}/users/validate-referral`, {
    method: 'POST',
    body: JSON.stringify({ referral_code: referral })
  })

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setValidate(false)
        handleSubmit(data.referrer_user_id)
      })
    } else if (!loading) {
      if (response.status === 404) {
        setErrorMessage('Código de referido inválido')
      } else {
        setErrorMessage('Ocurrió un error, por favor intentá de nuevo')
      }
      setTimeout(() => {
        setValidate(false)
      }, 2000)
    }
  }, [loading])

  if (loading) {
    return (
      <div className='flex items-center justify-center fixed bottom-8 right-0 w-full'>
        <LoadingIndicator />
      </div>
    )
  }

  if (!loading && !response.ok) {
    return <SnackBar message={errorMessage} setMessage={setErrorMessage} type='error' />
  }
}
