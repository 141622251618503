export default ({ images }) => {
  const positions = [
    [{ className: 'absolute inset-0 w-full h-full' }], // 1 image
    [
      { className: 'absolute top-0 left-0 w-1/2 h-full' },
      { className: 'absolute top-0 right-0 w-1/2 h-full' }
    ], // 2 images
    [
      { className: 'absolute top-0 left-0 w-1/2 h-1/2' },
      { className: 'absolute top-0 right-0 w-1/2 h-1/2' },
      { className: 'absolute bottom-0 w-full h-1/2' }
    ], // 3 images
    [
      { className: 'absolute top-0 left-0 w-1/2 h-1/2' },
      { className: 'absolute top-0 right-0 w-1/2 h-1/2' },
      { className: 'absolute bottom-0 left-0 w-1/2 h-1/2' },
      { className: 'absolute bottom-0 right-0 w-1/2 h-1/2' }
    ] // 4+ images
  ]

  const layout = positions[Math.min(images.length - 1, 3)]

  return (
    <div className='relative flex items-center justify-center w-12 h-12 rounded-full overflow-hidden'>
      {layout.map((pos, index) => (
        <img
          key={index}
          className={`${pos.className} object-cover`}
          src={images[index]}
          alt={`User ${index + 1}`}
        />
      ))}
    </div>
  )
}
