import { useAuth } from '../'
import { useState, useEffect } from 'react'
import auth0Config from '../../config/Auth0'

const useFetch = (url, fetchInfo) => {
  const [loading, setLoading] = useState(true)
  const [responseOk, setResponseOk] = useState(false)
  const [data, setData] = useState([])
  const { isAuthenticated, getAccessTokenSilently } = useAuth()

  useEffect(() => {
    setLoading(true)
    setData([])
    setResponseOk(false)
    const fetchData = async token => {
      fetch(url, {
        ...fetchInfo,
        headers: {
          'Content-Type': 'application/json',
          'authorization': token ? 'Bearer ' + token : ''
        }
      })
        .then(response => response.json())
        .then(data => {
          setResponseOk(true)
          setData(data)
          setLoading(false)
        })
        .catch(e => {
          setData(e)
          setLoading(false)
        })
    }

    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: auth0Config.audience
      }).then(token => fetchData(token))
    } else {
      fetchData()
    }
  }, [url])

  return { loading, data, responseOk }
}

export default useFetch
