import { useEffect, useState } from 'react'
import Lottie from 'lottie-react'
import { useAuthorizedFetch } from '../../hooks'
import { uri } from '../../config/Api'
import Success from './Success'
import RequestError from './RequestError'
import Denied from './Denied'
import { Mixpanel } from '../../Mixpanel'
import Pending from './Pending'

const BusVerifyPayment = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [paymentStatus, setPaymentStatus] = useState()
  const params = new URLSearchParams(window.location.search)
  const embedded = params.get('embedded') || localStorage.getItem('embedded')
  const transactionId = params.get('transaction_id')
  const { loading, response } = useAuthorizedFetch(`${uri}/buses/payment-status/${transactionId}`, {
    method: 'POST'
  })

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        logEvent(data.status, transactionId)
        setPaymentStatus(data)
      })
    } else if (response.ok == false) {
      setPaymentStatus('Request Error')
      Mixpanel.track('Error al verificar pago', {
        'Transaction Id': transactionId,
        'Response': response,
        'Status': response.status
      })
    }
  }, [loading])

  const handleSubmit = () => {
    if (embedded) {
      window.ReactNativeWebView?.postMessage('restart-bus-purchase')
    } else {
      localStorage.setItem('purchased', true)
      window.location.href = window.location.origin
    }
  }

  return (
    <>
      {loading || !paymentStatus ? (
        <div
          className='flex flex-col w-full items-center justify-center'
          style={{ height: height }}
        >
          <Lottie
            animationData={require('../../assets/animations/loading_payment.json')}
            autoplay
            loop
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            style={{ height: 150, width: 150 }}
          />
          <p className='text-lg text-primary'>Verificando tu pago...</p>
        </div>
      ) : paymentStatus?.status == 'approved' ? (
        <Success
          title={paymentStatus?.title}
          message={paymentStatus?.message}
          onSubmit={handleSubmit}
          height={height}
          width={width}
        />
      ) : paymentStatus?.status == 'denied' || paymentStatus?.status == 'failed' ? (
        <Denied
          status={paymentStatus?.status}
          title={paymentStatus?.title}
          message={paymentStatus?.message}
          url={paymentStatus?.payment_url}
          transactionId={transactionId}
          height={height}
        />
      ) : paymentStatus?.status == 'pending' ? (
        <Pending
          title={paymentStatus?.title}
          message={paymentStatus?.message}
          height={height}
          onSubmit={handleSubmit}
        />
      ) : (
        <RequestError height={height} width={width} onSubmit={handleSubmit} />
      )}
    </>
  )
}

const logEvent = (status, transactionId) => {
  if (status == 'approved') {
    Mixpanel.track('Unirse bus existoso', {
      'Transaction Id': transactionId
    })
  } else if (status == 'pending') {
    Mixpanel.track('Unirse bus pendiente', {
      'Transaction Id': transactionId
    })
  } else if (status == 'failed') {
    Mixpanel.track('Unirse bus fallado', {
      'Transaction Id': transactionId
    })
  } else {
    Mixpanel.track('Unirse bus denegado', {
      'Transaction Id': transactionId
    })
  }
}

export default BusVerifyPayment
